import posthog, { PostHog } from "posthog-js";
import { useEffect, useState } from "react";

// TODO - is there a way to put this in .client, since it should only run on the client? vite breaks if server
// cannot see it, despite this all being clientside stuff

export function usePosthog() {
    const [postHogInstance, setPostHogInstance] = useState<PostHog | null>(null);
    useEffect(() => {
        if (!posthog.__loaded) {
            posthog.init('phc_eibFmuqxEsQ6AarNoNYg4WmFN1bzCjriTprFKYjqec7', {
                api_host: 'https://tukey.findpowerpeople.com',
            });
        }
        setPostHogInstance(posthog);
    }, [])

    return postHogInstance;
}
